import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className="masthead flex bb1 m-wrap">
    <div className="flex space-between p15 w-50 m-100">
      <Link to="/" className="link bold text-small flex uppercase internal-link m-ma">
        <p className="m0 mr40">The</p>
        <p className="m0 mr40">GM</p>
        <p className="m0">Company</p>
      </Link>
    </div>
    <div className="menu mobile-menu m-bt1 p15 mla flex uppercase w-50 space-between text-small m-100">
      <Link to="/" className="link internal-link bold m0 mr20 m-hide" activeClassName="active">Home</Link>
      <Link to="/approach" className="link internal-link bold m0 mr20" activeClassName="active">Our Approach</Link>
      <Link to="/about" className="link internal-link bold m0 mr20" activeClassName="active">About Us</Link>
      <Link to="/contact" className="link internal-link bold m0" activeClassName="active">Contact</Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
