import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "../scss/animation.scss"
import "../scss/layout.scss"
import "../scss/global.scss"
import "../scss/header.scss"
import "../scss/links.scss"
import "../scss/dropdown.scss"
import "../scss/form.scss"
import "../scss/preloader.scss"

const Layout = ({ children, page, classes }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  var preloader;
  if(page==='index') {
    preloader = <div className="preloader"><div className="text m-mb50"><span>The</span> <span>Gm</span> <span>Company</span></div></div>
  }

  return (
    <div className={classes}>
      {preloader}
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="page--fade-in">{children}</main> 
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
