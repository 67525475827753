import * as React from "react"
import logo from "../images/tgmc.svg" 
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <div className="colophon mh-100 flex m-mb50" data-sal>
    <div className="p20 align-bottom flex flex-wrap text-center w-100">
      <div className="w-100">
        <div className="w-52 m-100 mla mb40 text-small">
          <div className="menu flex space-between uppercase m-hide">
            <Link to="/" className="link internal-link bold m0 mr20" activeClassName="active">Home</Link>
            <Link to="/approach" className="link internal-link bold m0 mr20" activeClassName="active">Our Approach</Link>
            <Link to="/about" className="link internal-link bold m0 mr20" activeClassName="active">About Us</Link>
            <Link to="/contact" className="link internal-link bold m0 mr20" activeClassName="active">Contact</Link> 
          </div>
          <div className="location bold uppercase mt20 flex m-small">
            <p className="m0">Brisbane<br/>Australia</p>
            <p className="m0 mla menu">
              <a className="link" href="mailto:troy@thegmcompany.com.au">troy@thegmcompany.com.au</a>
            </p>
          </div>
        </div>
      </div>
      <img className="w-100 align-bottom logo max-1600 ma" alt="TMGC Logo" src={logo} />
    </div>
  </div>
)

export default Footer
